@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

/* Remove default margin and padding */
html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
ul,
ol,
li,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
label,
figure,
figcaption {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box !important;
}

/* Set box-sizing to border-box for all elements */
*,
*:before,
*:after {
    box-sizing: border-box;
}

/* Set default line-height and font family */
body {
    line-height: 1;
    font-family: Arial, Helvetica, sans-serif;
}

/* Remove list styles */
ul,
ol {
    list-style: none;
}

/* Remove anchor text decoration */
a {
    text-decoration: none;
}

/* Set default anchor color */
a:link,
a:visited {
    color: #333;
}

/* Set default anchor color on hover */
a:hover,
a:focus {
    color: #000;
}

/* Remove table border */
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    background-color: #303030;
}

.home-container {
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 2%;
    min-height: 100vh;
    background: url('./features/assets/shadow.png');
}

.home-container>h1 {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding-top: 240px;
    border-top: 1px solid transparent;
}

.home-container>p {
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.home-button-box {
    width: 648px;
    height: 47px;
    margin: 0 auto;
    display: flex;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    overflow: hidden;
}

.home-button-box input {
    width: 80%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    background: none;
    padding: 0 20px;
}

.home-button-box button {
    width: 20%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 20px;
    font-weight: normal;
    color: #000;
    background: #ffa000;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
}

.services-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    margin: 20px;
    padding: 20px;
    background: #424242;
    border: 1px solid #9c27b0;
    background: linear-gradient(209.21deg,
            rgba(23, 23, 27, 0.034) 13.57%,
            rgba(120, 63, 211, 0.363) 98.38%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
    text-align: center;
    margin-left: 10px;
}

.services-item-none {
    width: 0;
    height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.services-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

.services-item-header {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    border-radius: 12px;
    padding: 50px 10px;
    color: #fff;
    background: radial-gradient(100% 128.38% at 100% 100%,
            rgba(51, 168, 253, 0.2) 0%,
            rgba(76, 0, 200, 0.2) 100%);
}

.services-item-title {
    font-size: 18px;
    font-weight: normal;
    margin: 20px 0;
    color: rgba(255, 255, 255, 0.7);
}

.services-item-button {
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #000;
    background: transparent;
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(217, 223, 255) 100%);
    border-radius: 8px;
    border: none;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset,
        rgba(23, 0, 102, 0.2) 0px 20px 40px, rgba(0, 0, 0, 0.1) 0px 1px 3px;
}

.services-item-button:hover {
    background-color: #0062cc;
}

.not-found {
    width: 648px;
}

.home-marketplace-container {
    background: url('./features/assets/shadow.png');
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 5fr;
}

.side-menu {
    background: #424242;
    border-right: 1px solid #9c27b0;
    background: linear-gradient(209.21deg,
            rgba(23, 23, 27, 0.034) 13.57%,
            rgba(120, 63, 211, 0.363) 98.38%);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease-in-out;
    text-align: center;
}

.main-marketplace-content {
    background: url('./features/assets/shadow.png');
    min-height: 100vh;
}

/* MarketPlace Component Styles */

/* Container for entire component */
.marketplace-container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 1rem 0;
    overflow-y: scroll;
    height: 100vh;
}

.marketplace-container::-webkit-scrollbar {
    width: 0.5rem;
}

/* Header section */
.marketplace-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0px 170px;
}

.marketplace-header h1 {
    font-size: 2rem;
}

/* Controls section */
.marketplace-controls {
    display: flex;
    align-items: center;
}

.search-control input[type='text'] {
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    background: #484848;
    color: white;
}

.sort-control label {
    margin-right: 0.5rem;
}

.sort-control select {
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    background: #484848;
    color: white;
}

/* Product list section */
.product-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 80px;
    justify-content: center;
}

/* Pagination section */
.pagination-container {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.pagination {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.pagination li {
    font-size: 1rem;
    margin: 12px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    border-radius: 100%;
    border: 1px solid #ccc;
    cursor: pointer;
}

.pagination li.active {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.product-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 30px;
    height: 430px;
    width: 350px;
    overflow-y: auto;
    border: 1px solid #9c27b0;
    background-color: #414141;

    background: #424242;
    box-shadow: inset 0 -5px 0 #313686, 1px 4px 5px -3px #bd9f9f59;
    border-radius: 20px;
    overflow: hidden;
}

.product-card::-webkit-scrollbar {
    width: 2px;
}

.product-card::-webkit-scrollbar-track {
    background: #f1f502;
}

.product-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.product-image img {
    max-width: 100%;
    height: auto;
}

.product-info h3 {
    font-size: 1.5rem;
    margin-bottom: 8px;
    font-family: 'Satisfy', cursive;
    white-space: nowrap;
}

.product-info p {
    margin-bottom: 8px;
    border-radius: 20px;
    box-sizing: border-box;
    backdrop-filter: blur(40px);
    border-radius: 12qpx;
    padding: 20px;
    min-height: 170px;
    font-style: normal;
    font-size: 18px;
    font-weight: normal;
    overflow: hidden;
    background-color: rgba(56, 139, 253, 0.15);
    border: 1px solid #1f6feb;
    border-radius: 8px;
    color: #fff;
}

.product-price {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.product-rating {
    margin-bottom: 8px;
}

.product-buttons button {
    border: none;
    padding: 8px 16px;
    margin-right: 8px;
    cursor: pointer;
    border-radius: 6px;
}

.add-to-cart-button {
    background-color: #007bff;
    color: #fff;
}

.add-to-wishlist-button {
    background-color: #dc3545;
    color: #fff;
}

.add-to-cart-button[disabled],
.add-to-wishlist-button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.related-products {
    margin-top: 16px;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 20px;
}

.related-products>button {
    width: 100%;
    height: 100%;
    background: radial-gradient(99.77% 100% at 31.97% 99.5%,
            hsla(0, 0%, 100%, 0) 0,
            rgba(189, 138, 184, 0.3) 100%) #3434b9;
    border-color: transparent;
    border-radius: 8px;
    box-shadow: inset 0 -5px 0 #313686;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    color: white;
    /* padding: 10px 0; */
}

.related-product>button:hover {
    background: #9c27b0;
    color: white;
}

.related-products h4 {
    font-size: 1.25rem;
    margin-bottom: 8px;
}

.related-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
    padding: 8px 0;
}

.related-product img {
    max-width: 100%;
    height: auto;
    margin-bottom: 8px;
}

.related-product h5 {
    font-size: 1rem;
    margin-bottom: 8px;
}

.related-product-price {
    font-weight: bold;
}

.product-image>h1 {
    font-size: 24px;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.12);
    box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 0.5px inset;
    color: white;
    line-height: 28px;
    width: 100%;
}

.modal-user-login {
    width: 592px;
    height: 564px;
    background: #041016 url("./features/assets/devdoctorslogo2.svg");
    transition: 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    z-index: 15;
    top: calc((100vh - 564px) / 2);
    border: 1px solid #777777;
    border-radius: 12px;
    padding: 20px;
    left: calc(50% - 250px);
    animation-name: vibration;
    animation-delay: 0.7s;
    animation-duration: 0.2s;
    animation-iteration-count: 5;
    animation-direction: normal;
    animation-fill-mode: none;
}

.modal-user-login-none {
    width: 592px;
    height: 564px;
    background-color: transparent;
    transition: 0.7s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    z-index: 15;
    top: -800px;
    border: 1px solid #383d47;
    border-radius: 12px;
    padding: 20px;
    left: calc(50% - 250px);
}

.close-modal-user-login {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #383d4734;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #77777734;
    color: white;
}


.close-modal-user-login:hover {
    background-color: white;
    border: 1px solid #777777;
    color: black;
}

@keyframes vibration {
    0% {
        transform: translate(0);
    }

    10% {
        transform: translate(-2px, 2px);
    }

    20% {
        transform: translate(-2px, -2px);
    }

    30% {
        transform: translate(2px, 2px);
    }

    40% {
        transform: translate(2px, -2px);
    }

    50% {
        transform: translate(-2px, 2px);
    }

    60% {
        transform: translate(-2px, -2px);
    }

    70% {
        transform: translate(2px, 2px);
    }

    80% {
        transform: translate(2px, -2px);
    }

    90% {
        transform: translate(-2px, 2px);
    }

    100% {
        transform: translate(0);
    }
}



.user-info {
    border: 1px solid #777777;
    border-radius: 8px;
    background-color: #383d47!important;
}

.user-cta > p {
    font-size: 1.25rem;
    margin-bottom: 8px;
    padding: 12px;
    text-align: justify;

}