.hero {
    margin-top: 55px;
    width: 100%;
    color: white;
    margin-bottom: 120px;
}
.hero_top_content {
    display: flex;
    justify-content: space-between;
}
.hero_img>img{
    width: 100%;
}
.hero_top_content>div{
    width: 50%;
}
.hero_content {
    max-width: 630px;
}
.hero_content > h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 20px;
    width: 100%;
}
.hero_content > p {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 32px;
    margin-right: 20px;
}
.hero_button_box > button {
    padding: 10px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 6px;
    border: 1px solid transparent;
}
.button_started {
    margin-right: 16px;
    color: white;
    background-color: #9c27b0;
}

.button_view {
    color: #e1bee7;
    background-color: transparent;
    border-color: #e1bee7 !important;
}

.HeroPagination {
    display: flex;
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 85px;
}
.hero_pagination_item > div > h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
}
.hero_pagination_item > div > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
}
.hero_pagination_item {
    display: flex;
    align-items: center;
}
.hero_pagination_item:first-child > .line {
    display: none;
}
.hero_pagination_item:first-child > div > h3 {
    color: #ff6f00;
    border: none;
}

.hero_pagination_item:first-child > div > p {
    color: #e1bee7;
}
.line {
    height: 90%;
    width: 2px;
    background-color: rgba(255, 255, 255, 0.12);
    margin-right: 50px;
}

.MoreFeatures {
    margin-top: 70px;
    margin-left: 100px;
    /* margin-right: -30px; */
}

.MoreFeatures_box {
    display: flex;
    align-items: center;
}
.MoreFeatures_box>div{
    width: 50%;
}
.MoreFeatures_img>img{
    width: 100%;
}
.MoreFeatures_content:first-child {
    margin-right: 70px;
}
.MoreFeatures_content:last-child {
    margin-left: 70px;
}
.MoreFeatures_content > h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
}
.MoreFeatures_content > p {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    max-width: 600px;
}
.MoreFeatures_box:first-child {
    margin-bottom: 120px;
}

.ServicesBox {
    margin-top: 120px;
}

.ServicesBox > h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 24px;
}

.ServicesBox > p {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 80px;
}
.Services_card {
    width: 350px;
    height: 350px;
    border-radius: 20px;
    border: 1px solid #8c289e;
    background-color: #424242;
    margin-right: 20px;
}

.ServicesBox_box {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}
.ServicesBox_box>img{
    width: 80%;
}
.ServicesBox_content {
    display: flex;
}
.Testimonials {
    margin-top: 80px;
    margin-bottom: 209px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.Testimonials > h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
}

.Testimonials > p {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 56px;
}
.Testimonials_caruse,
.rectangle1,
.rectangle2 {
    border-radius: 20px;
    background-color: #424242;
    box-shadow: 0px 3px 49px 9px rgba(0, 0, 0, 0.06);
}
.Testimonials_caruse {
    width: 900px;
    border: 1px solid rgba(106, 27, 154, 1);
}
.Testimonials_carusel_profile {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: -60px;
    margin-bottom: 38px;
}
.Testimonials_caruse > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin: 0 90px 50px 90px;
}

.rectangle1 {
    width: 870px;
    height: 100px;
    position: absolute;
    bottom: -35px;
    z-index: -1;
}

.rectangle2 {
    width: 800px;
    height: 100px;
    position: absolute;
    bottom: -70px;
    z-index: -2;
}
.NewsEvent {
    display: flex;
    margin-bottom: 300px;
        justify-content: space-between;

}
.NewsEvent_item {
    display: flex;
    margin-left: 20px;
    align-items: center;
}
.NewsEvent_item_img {
    margin-right: 16px;
}
.NewsEvent_item_img >img{
    max-width: 120px;

}
.NewsEvent_item_content > h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.54);
    margin-bottom: 10px;
}

.NewsEvent_item_content > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
    max-width: 300px;
}

.NewsEvent_item_content > a {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #e1bee7 !important;
}
.MainFeatures>img{
    width: 100%;
}


@media screen and (max-width: 1200px) {
    .HeroPagination{
        margin-left: 50px;
        margin-right: 50px;
    }
    .hero_pagination_item {
        flex-direction: column;
        align-items: flex-start;
    }
    .hero_pagination_item > div {
        margin-bottom: 50px;
    }
    .hero_pagination_item > div > h3 {
        font-size: 24px;
        line-height: 36px;
    }
    .hero_pagination_item > div > p {
        font-size: 16px;
        line-height: 24px;
    }
    .hero_pagination_item > .line {
        display: none;
    }
    .MoreFeatures {
        margin-top: 70px;
        margin-left: 0;
    }
    .MoreFeatures_content {
        margin-bottom: 70px;
    }
    .MoreFeatures_content > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .MoreFeatures_content > p {
        font-size: 18px;
        line-height: 24px;
    }
    .ServicesBox {
        margin-top: 70px;
    }
    .ServicesBox > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .ServicesBox > p {
        font-size: 18px;
        line-height: 24px;
    }
    .Services_card {
        margin-bottom: 20px;
        width: 300px;
        height: 300px;
    }
    .Testimonials {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .Testimonials > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .Testimonials > p {
        font-size: 18px;
        line-height: 24px;
    }
    .Testimonials_caruse{
        margin-left: auto;
        margin-right: auto;
    }
    .rectangle1{
        right: -9%;
    }
    .rectangle2{
        right: -5%;
    }
    .NewsEvent{
        margin-bottom: 200px;
    }
    .NewsEvent_item {
        margin-bottom: 20px;
        margin-top: 40px;
    }
    .NewsEvent_item_img {
        margin-right: 10;
    }
    .NewsEvent_item_content > h5 {
        font-size: 12px
    }
    .NewsEvent_item_content>h5{
        font-size: 12px;
    }
    .NewsEvent_item_content > p {
        font-size: 12px;
    }
    .NewsEvent_item_img>IMG{
        max-width: 90px;
    }
}

@media screen and (max-width:950px) {
    
    .hero_pagination_item {
        flex-direction: column;
        align-items: flex-start;
    }
    .hero_pagination_item > div {
        margin-bottom: 50px;
    }
    .hero_pagination_item > div > h3 {
        font-size: 24px;
        line-height: 36px;
    }
    .hero_pagination_item > div > p {
        font-size: 16px;
        line-height: 24px;
    }
    .hero_pagination_item > .line {
        display: none;
    }
    .MoreFeatures {
        margin-top: 70px;
        margin-left: 0;
    }
    .MoreFeatures_content {
        margin-bottom: 70px;
    }
    .MoreFeatures_content > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .MoreFeatures_content > p {
        font-size: 18px;
        line-height: 24px;
    }
    .ServicesBox {
        margin-top: 70px;
    }
    .ServicesBox > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .ServicesBox > p {
        font-size: 18px;
        line-height: 24px;
    }
    .Services_card {
        margin-bottom: 20px;
        width: 300px;
        height: 300px;
    }
    .ServicesBox_box{
        width: 100px;
    }
    .Testimonials {
        margin-top: 70px;
        margin-bottom: 70px;
        margin-left: auto;
        margin-right: auto;
    }
    .Testimonials_caruse{
        width: 650px;
        margin: 0 50px 30px 50px;
    }
    .rectangle1{
        display: none;
    }
    .rectangle2{
        display: none;
    }
    .Testimonials > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .Testimonials > p {
        font-size: 18px;
        line-height: 24px;
    }
    .NewsEvent{
        flex-direction: column;
        align-items: center;
        margin-bottom: 150px;
    }
    .NewsEvent_item {
        margin-bottom: 20px;
        margin-top: 40px;
        
    }
    .NewsEvent_item_img {
        margin-right: 10;
    }
    .NewsEvent_item_content>h5{
        font-size: 14px;
    }
    .NewsEvent_item_content > p {
        font-size: 14px;
    }
    .NewsEvent_item_img>img{
        max-width: 120px;
    }
}

@media screen and (max-width:600px) {
    .hero_content>h1{
        font-size: 20px;
        line-height: 30px;
    }
    .hero_content>p{
        font-size: 14px;
        line-height: 20px;
    }
    .hero_button_box>button{
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
    }
    .hero_pagination_item {
        margin-left: 10px;
    }
.hero_pagination_item:last-child{
    margin-left: 0;
}
    .hero_pagination_item > div > h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .hero_pagination_item > div > p {
        font-size: 14px;
        line-height: 20px;
    }
    .ServicesBox {
        margin-top: 70px;
    }
    .ServicesBox > h3 {
        font-size: 28px;
        line-height: 42px;
    }
    .ServicesBox > p {
        font-size: 18px;
        line-height: 24px;
    }
    .Services_card {
        margin-bottom: 20px;
        width: 250px;
        height: 250px;
    }
    .Testimonials{
        margin-top: 70px;
        margin-bottom: 70px;
        margin-left: auto;
        margin-right: auto;
    }
    .Testimonials_caruse{
        width: 500px;
        margin: 0 30px 15px 30px;
    }
    .Testimonials_caruse>p{
        font-size: 16px;
        line-height: 20px;
        margin:-20px 45px 30px 45px;
    }


}